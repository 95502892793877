import "./App.css";
import {useState, useEffect} from "react"; 
import Banner from "./component/Banner";

function App() {
  const [showHomeLogo,setShowHomeLogo]=useState(true);
  const handleDownload = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check if the user is using an iOS device
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.open('https://apps.apple.com/in/app/bookr-your-personal-library/id6449678217', '_blank'); // Replace 'your-app-id' with your actual App Store ID
    } else if (/android/i.test(userAgent)) {
      window.open('https://play.google.com/store/apps/details?id=com.share.bookR&hl=en-IN', '_blank'); // Replace 'your.package.name' with your actual package name
    } else {
      // Redirect to a generic download page for other devices
      window.open('https://play.google.com/store/apps/details?id=com.share.bookR&hl=en-IN', '_blank');
    }
  };
  useEffect(()=>{
    setTimeout(()=>{
     setShowHomeLogo(false);
    },1500)
  },[])
  const images = [
    "/images/book16.png",
    "/images/book15.png",
    "/images/book14.png",
    "/images/book13.png",
    "/images/book12.png",
    "/images/book11.png",
    "/images/book10.png",
    "/images/book9.png",
    "/images/book8.png",
    "/images/book7.png",
    "/images/book6.png",
    "/images/book5.png",
    "/images/book4.png",
    "/images/book3.png",
    "/images/book2.png",
    "/images/book1.png",    
  ].map((image) => ({
    id: crypto.randomUUID(),
    image
  }));
  return (
    <div className="App">
      {/* home start */}
      <div className="homeContainer">
        {showHomeLogo ? (
          <div className="brandLogoCenter">
          <img src="/images/loaderLogo.png" alt="img"/>
            </div>
        ) : (
          <>
            {/* nav Start */}
            <nav className="nav">
              <div className="brand">
                <img src="/images/brandLogo.png" alt="img" />
              </div>
              <div className="d-flex socialIcons">
                <img style={{cursor: "pointer"}} onClick={() => {window.open('https://www.facebook.com/mybookr.club', '_blank')}} src="/images/f.png" alt="img" />
                <img style={{cursor: "pointer"}} onClick={() => {window.open('https://twitter.com/mybookr', '_blank')}} src="/images/twitter.png" alt="img" />
                <img style={{cursor: "pointer"}} onClick={() => {window.open('https://www.linkedin.com/company/mybookr', '_blank')}} src="/images/linked_in.png" alt="img" />
              </div>
            </nav>
            {/* nav end*/}

            {/* home content start */}
            <div className="homeContent d-flex">
              <div className="headers">
                <h1 className="mb-0">Discover a World of Books with BookR!</h1>
                <p className="p1">
                  Are you a passionate book lover? Do you enjoy immersing yourself in captivating stories and
                  thought-provoking ideas?
                </p>
                <p className="p2">
                  Look no further! Introducing BookR, the ultimate book sharing app designed to connect book enthusiasts
                  like you!
                </p>
                <button onClick={handleDownload}>DOWNLOAD THE APP NOW</button>
              </div>
              <div className="bannerImg" alt="img">
                <img src="/images/mobilePic.png" alt="img" />
              </div>
            </div>
            {/* home content end */}
          </>
        )}

        {/* book img gallery start */}
        
        <div className="bookGallery">
          <Banner images={images} speed={12000} slideType={"leftSlide"}/>
          <h1>AN EVER EXPANDING LIBRARY</h1>
          <Banner images={images} speed={12000} slideType={"rightSlide"}/>
        </div>
        {/* book img gallery end */}

        {/* detail start1 */}
        <div className="d-flex justify-content-around detail">
          <img src="/images/detail1.png" alt="img" />
          <div className="m-auto">
            <h1 className="firstH1">MEET FELLOW ETHUSIASTS & FORM BOOK CLUBS</h1>
          </div>
        </div>
        {/* detail end */}
        {/* detail start1 */}
        <div className="d-flex justify-content-around detail">
          <div className="m-auto">
            <h1 className="firstH2">ORDER OR SHARE BOOKS SAFE DELIVERY ASSURED</h1>
          </div>
          <img src="/images/detail1.png" alt="img" />
        </div>
        {/* detail end */}

        {/* footer start */}
        <div className="footer d-flex justify-content-between">
          <div className="footerContent my-auto">
            <h1>Download BookR</h1>
            <p> & Explore A Kingdom Of Books</p>
            <div  onClick={() => {window.open('https://play.google.com/store/apps/details?id=com.share.bookR&hl=en-IN', '_blank')}} className="playButton">
              <img src="/images/appstore.png" alt="img"/>
            </div>
            <div  onClick={() => {window.open('https://apps.apple.com/in/app/bookr-your-personal-library/id6449678217', '_blank')}} className="playButton">
              <img  src="/images/playstore.png" alt="img"/>
            </div>
          </div>
          <div>
            <img src="/images/footerBanner.png" alt="img" />
          </div>
        </div>
        {/* footer end */}

        {/* copyRight start */}
        <div className="copyRight">
          <div className="d-flex justify-content-between">
            <div>
              <img src="/images/copyRight.png" alt="img" />
            </div>
            <div className="my-auto">
              <img src="/images/arrow_upward.png" alt="img"/>
            </div>
          </div>
          <p>© BookR</p>
        </div>
        {/* copyRight end */}
      </div>
      {/* home end */}
    </div>
  );
}

export default App;